import React from 'react';

function App() {
    return (
        <div
            style={{
                backgroundImage: `url(/photos/background.jpeg)`,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <img
                src="/photos/logoGG.png"
                alt="Garlick Gardens Logo"
                style={{
                    maxWidth: '100%',
                    maxHeight: '70%',
                    marginTop: '-300px',
                }}
            />
        </div>
    );
}

export default App;
